import React from 'react'

const Amazon = () => {
  return (
    <div>
  <section className="content">
  <div className="content-wrap">
    <header className="content-header"> 
      <section className="page-intro">
        <h1 className="page-title">Amazon</h1>
      </section>
    </header>
</div>

</section>
</div>
  )
}

export default Amazon