import React from 'react';
import './header.css';
import { Navbar } from '../../components';
import { Link } from 'react-router-dom';
const Header = () => {
  return (
  

<header className="site-header">
  <div className="nav-area">
     <a className="logo" data-proofer-ignore href="/"> <img className="logo-image" src="https://media.thomasridley.co.uk/commerce/faq/assets/logo.png" alt="" /> <span className="logo-text"></span> <span className="site-logo">Staff Resources</span> </a>
           
        
<Navbar />

        
  </div>
  <div className="nav-main-fader"></div>
</header>


  )
}

export default Header