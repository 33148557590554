import React from "react";

const App = () => {
  return (
  <div>
  <section className="content">
  <div className="content-wrap">
    <header className="content-header"> 
      <section className="page-intro">
        <h1 className="page-title">Page Builder - Image</h1>
      </section>
    </header>
    <main>
      <p>This Chapter covers all aspects of Image Element of Magento Page Builder.</p>
      <p className="bs-callout-info">Introduces "Max Height" property. Images might need to be reasigned to use it.</p>
      <h2 id="max-height-issue">Issues with image max height</h2>
      <p>When images are uploaded they are randomly scaled by width to fit content.</p>
  <p>This might cause allighment issues when creating pages.</p>
  <p><img src="https://media.thomasridley.co.uk/commerce/faq/img/pb-image-issue.jpg" alt="" className="zoom" /> <em>Allighment issue</em></p>
      <ol>
        <li>
          <p>Go to Page or Blog post you want to <em>fix</em>. Select Edit with Page Builder.</p>
        </li>
        <li>
          <p>Select <em>Edit</em> from the <strong>Image Tab</strong>.</p>
          <p><img src="https://media.thomasridley.co.uk/commerce/faq/img/pb-image-edit.png" alt="" className="zoom" /> <em>Edit Image</em></p>
        </li>
   <li>
          <p>Check the height of <em>Image</em>, in this case it's <strong>250 px</strong>.</p>
          <p><img src="https://media.thomasridley.co.uk/commerce/faq/img/pb-image-size.png" alt="" className="zoom" /> <em>Image Size</em></p>
        </li>
        <li>
          <p>Uste the <strong>Maximum Height</strong> seting on the image that is <em>miss allighned</em> to adjust the height to the same size as smaller image in the row.</p>
       <p><img src="https://media.thomasridley.co.uk/commerce/faq/img/pb-image-heigh-location.png" alt="" className="zoom" /> <em>Max Height Location</em></p>
     <p><img src="https://media.thomasridley.co.uk/commerce/faq/img/pb-image-heigh.png" alt="" className="zoom" /> <em>Max Height</em></p>
        </li>
        <li>
          <p>Repeate the proces on <em>all images that need adjusting</em>, once done save the works and <strong>Page / Blog post</strong> .</p>
          <p><img src="https://media.thomasridley.co.uk/commerce/faq/img/pb-image-heigh-fixed.png" alt="" className="zoom" /> <em>Fixed Post</em></p>
        </li>
      </ol>
      <p className="bs-callout-tip">Please rememebr to fully refresh the Page ( CTRL + F5) <em><strong> to see the effects on live page.</strong></em> </p>
      <h2 id="border-modifications">Changing Color , Size etc</h2>
      <ol>

      </ol>
    </main>
  </div>
  
  <div className="page-info"> </div>
</section>
</div>
  )
}

export default App