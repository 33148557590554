import React from 'react'
import './sidebar.css';
import { Sidenav } from '../../components';
const Sidebar = () => {
  return (
<div className='sidebar-menu'>
  <div className='sidebar-heading'>
    Thomas Ridley
  </div>
 <Sidenav />
</div>
  )
}

export default Sidebar