export const menuSidebarData = [
    {
      title: "Info",
      url: "/Info",
    },
    {
      title: "Magento",
      url: "/Magento",
      submenu: [
        {
          title: "Products",
          url: "/M-Products",
        },
        {
          title: "Marketing",
          url: "/M-Marketing",
        },
      ],
    },
    {
      title: "M2e",
     url: "/M2e",
      submenu: [
        {
          title: "eBay",
          url: "/Ebay",
        },
        {
          title: "Our values",
          url: "/our-values",
        },
        {
          title: "Amazon",
          url: "/Amazon",
          submenu: [
            {
              title: "Order",
              url: "/frontend",
            },
            {
              title: "Items",
              submenu: [
                {
                  title: "NodeJS",
                  url: "/node",
                },
                {
                  title: "PHP",
                  url: "/php",
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  