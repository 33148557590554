import React from 'react' 
import './navbar.css'
import MenuItems from './ManuItem';
import { menuItemsData } from '../../menuItemsData';

const Navbar = () => {

  return (
    
     <nav className="desktop-nav">
      <ul className="menus">
        {menuItemsData.map((menu, index) => {
            const depthLevel = 0;
          return <MenuItems items={menu} key={index} depthLevel={depthLevel}/>;
        })}
      </ul>
    </nav>
  )
}

export default Navbar