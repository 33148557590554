import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import './index.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Amazon,Magento , M2e , Info , Ebay ,MagentoMarketing ,MagentoProducts } from "./routes/index"
import Root from "./routes/root";
import Services from "./routes/services";
import WebDesign from "./routes/webdesign";
import WebDev from "./routes/webdev";

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "Info",
        element: <Info />,
      },
      {
        path: "Ebay",
        element: <Ebay />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "web-design",
        element: <WebDesign />,
      },
      {
        path: "web-dev",
        element: <WebDev />,
      },
      {
        path: "Magento",
        element: <Magento />,
      },
      {
        path: "M2e",
        element: <M2e />,
      },
      {
        path: "Amazon",
        element: <Amazon />,
      },
      {
        path: "M-Products",
        element: <MagentoProducts />,
      },
      {
        path: "M-Marketing",
        element: <MagentoMarketing />,
      },
    ],
  },
]);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
   <RouterProvider router={router} />
  </React.StrictMode>
);