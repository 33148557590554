import React from 'react'
import { Outlet } from "react-router-dom";
import { Header,Footer,Sidebar } from "./../containers";
import './../app.css'
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import "@fontsource/source-sans-pro/400-italic.css"; // Specify weight and style
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "@fontsource/ubuntu"; 

const root = () => {
  return (
    <div className='layout-default'>
    <div className='site-header'>
<Header />
    </div> 
    <div className='global-wrapper'>
      <div className='main-container'> <a id="main-content"></a>
      <div className='content-container'>
<aside className='sidebar'>
<Sidebar />
</aside>

<section className='content'>
<div className="content-wrap">
<Outlet />

</div>
</section>
      </div>
      </div>
      </div>  
      <div className='page-footer'>
<Footer />

</div>    
  
  </div>
)
}

export default root


