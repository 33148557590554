import React from 'react'

const info = () => {
  return (
    <div>
    <section className="content">
    <div className="content-wrap">
      <header className="content-header"> 
        <section className="page-intro">
          <h1 className="page-title">Info</h1>
        </section>
      </header>
  </div>
  
  </section>
  </div>
  )
}

export default info