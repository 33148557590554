// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    max-width: 270px;
    background-color:rgb(234, 234, 234) ;
    min-height: 450px;
    min-width: 270px;
    
}
.sidebar-heading {
    padding: 20px 15px;
    font-weight: 700;
}

.sidebar-items .button {
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/containers/sidebar/sidebar.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,oCAAoC;IACpC,iBAAiB;IACjB,gBAAgB;;AAEpB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".sidebar {\n    max-width: 270px;\n    background-color:rgb(234, 234, 234) ;\n    min-height: 450px;\n    min-width: 270px;\n    \n}\n.sidebar-heading {\n    padding: 20px 15px;\n    font-weight: 700;\n}\n\n.sidebar-items .button {\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
