// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desktop-side-nav .menus {
        display: flex;
        padding: 10px;
        flex-wrap: nowrap;
        flex-direction: column;

     }  

      
     .sidebar-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
      }
      
      .sidebar-item-content {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;
        align-items: center;
        width: 100%;
      }
      
      .sidebar-item-icon {
        margin-right: 6px;
      }
      
      .sidebar-item-text {
        width: 100%;
      }
      
      .sidebar-item-expand-arrow {
        font-size: 1.2rem !important;
      }
      
      .sidebar-item-expand-arrow-expanded {
        color: #09bb12;
        font-weight: bold;
      }

      `, "",{"version":3,"sources":["webpack://./src/components/sidenav/sidenav.css"],"names":[],"mappings":"AAAA;QACQ,aAAa;QACb,aAAa;QACb,iBAAiB;QACjB,sBAAsB;;KAEzB;;;KAGA;QACG,aAAa;QACb,8BAA8B;QAC9B,uBAAuB;QACvB,sBAAsB;MACxB;;MAEA;QACE,mBAAmB;QACnB,uBAAuB;QACvB,gBAAgB;QAChB,aAAa;QACb,mBAAmB;QACnB,WAAW;MACb;;MAEA;QACE,iBAAiB;MACnB;;MAEA;QACE,WAAW;MACb;;MAEA;QACE,4BAA4B;MAC9B;;MAEA;QACE,cAAc;QACd,iBAAiB;MACnB","sourcesContent":[".desktop-side-nav .menus {\n        display: flex;\n        padding: 10px;\n        flex-wrap: nowrap;\n        flex-direction: column;\n\n     }  \n\n      \n     .sidebar-item {\n        display: flex;\n        justify-content: space-between;\n        align-items: flex-start;\n        flex-direction: column;\n      }\n      \n      .sidebar-item-content {\n        white-space: nowrap;\n        text-overflow: ellipsis;\n        overflow: hidden;\n        display: flex;\n        align-items: center;\n        width: 100%;\n      }\n      \n      .sidebar-item-icon {\n        margin-right: 6px;\n      }\n      \n      .sidebar-item-text {\n        width: 100%;\n      }\n      \n      .sidebar-item-expand-arrow {\n        font-size: 1.2rem !important;\n      }\n      \n      .sidebar-item-expand-arrow-expanded {\n        color: #09bb12;\n        font-weight: bold;\n      }\n\n      "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
