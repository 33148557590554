import React from 'react'
import "./cta.css"


const Cta = () => {
  return (
    <div>
      CTA
    </div>
  )
}

export default Cta