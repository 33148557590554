// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-area {
    display: flex;
    align-items: center;
    max-width: 1440px;
    justify-content: flex-start;
}
.logo-image {
    max-height: 50px;
  }

  .logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 18px;
    color: inherit;
    max-width: 270px;
    min-width: 270px;
    background-color:rgb(234, 234, 234) ;
    padding: 10px 0px 10px 10px;
  }
  .site-logo::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 1em;
    margin: 0 10px;
    border-left: 1px solid #4d4d4d;
}
.nav-main-fader {
    min-width: 1440px;
    border: 2px solid;
    border-color: rgb(234, 234, 234);
}`, "",{"version":3,"sources":["webpack://./src/containers/header/header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,2BAA2B;AAC/B;AACA;IACI,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,oCAAoC;IACpC,2BAA2B;EAC7B;EACA;IACE,WAAW;IACX,qBAAqB;IACrB,sBAAsB;IACtB,UAAU;IACV,WAAW;IACX,cAAc;IACd,8BAA8B;AAClC;AACA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,gCAAgC;AACpC","sourcesContent":[".nav-area {\n    display: flex;\n    align-items: center;\n    max-width: 1440px;\n    justify-content: flex-start;\n}\n.logo-image {\n    max-height: 50px;\n  }\n\n  .logo {\n    display: flex;\n    align-items: center;\n    text-decoration: none;\n    font-size: 18px;\n    color: inherit;\n    max-width: 270px;\n    min-width: 270px;\n    background-color:rgb(234, 234, 234) ;\n    padding: 10px 0px 10px 10px;\n  }\n  .site-logo::before {\n    content: \"\";\n    display: inline-block;\n    vertical-align: middle;\n    width: 1px;\n    height: 1em;\n    margin: 0 10px;\n    border-left: 1px solid #4d4d4d;\n}\n.nav-main-fader {\n    min-width: 1440px;\n    border: 2px solid;\n    border-color: rgb(234, 234, 234);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
