// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-block {
    min-height: 50px;
    background-color: aquamarine;
    align-content: center;
}`, "",{"version":3,"sources":["webpack://./src/containers/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,4BAA4B;IAC5B,qBAAqB;AACzB","sourcesContent":[".footer-block {\n    min-height: 50px;\n    background-color: aquamarine;\n    align-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
